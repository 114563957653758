<template>
  <div class="min-h-screen flex">
    <Sidebar />
    <div class="w-2/12"></div>
    <div class="p-4 w-10/12">
      <div class="flex items-center mb-4">
        <div class="is-size-4">{{ $route.name }}</div>
        <b-field
          class="file is-success mb-0 ml-4"
          v-if="userHasPermission('write-files')"
        >
          <b-upload v-model="files" multiple @input="onUpload">
            <span class="file-cta">
              <span class="file-label">Upload</span>
            </span>
          </b-upload>
        </b-field>
        <b-select
          v-model="$store.state.files.selectedType"
          class="ml-4"
          @change.native="onSelect"
        >
          <option value="*">All</option>
          <option
            v-for="(type, index) in data.types"
            :key="index"
            :value="type.slug"
            >{{ type.name }}</option
          >
        </b-select>
      </div>
      <div class="flex items-center justify-between mb-4">
        <div class="flex items-center">
          <b-button
            icon-left="checkbox-marked-outline"
            class=""
            @click="(checkable = !checkable), (checkedRows = [])"
            :disabled="$store.getters.isProcessing"
            v-if="userHasPermission('write-files')"
            >Select</b-button
          >
          <b-button
            v-if="checkedRows.length"
            icon-left="delete-outline"
            class="ml-4"
            @click="onDelete()"
            :disabled="$store.getters.isProcessing"
            >Delete ({{ checkedRows.length }})</b-button
          >
          <b-button
            v-if="checkedRows.length"
            icon-left="close"
            class="ml-4"
            @click="checkedRows = []"
            :disabled="$store.getters.isProcessing"
            >Clear</b-button
          >
        </div>
        <div @keyup.enter="getFiles">
          <b-input
            v-model="search"
            icon="magnify"
            placeholder="Search"
          ></b-input>
        </div>
      </div>
      <div class="px-2 is-relative">
        <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
        <div
          @scroll="onScroll"
          ref="files-container"
          class="-mx-4 flex flex-wrap files-container"
        >
          <div
            class="w-1/5 px-2 mb-4"
            v-for="(file, index) in data.data"
            :key="index"
          >
            <div
              class="file-item"
              :class="{ 'file-item-selected': itemSelected(file.id) }"
              @click="onBulkSelect(file.id)"
            >
              <b-image
                :src="
                  file.type == 'document' ? $default_document_thumb : file.thumb
                "
                :alt="file.filename"
                ratio="1by1"
                lazy
              ></b-image>
              <p class="bg-gray-200 p-2 break-all">{{ file.filename }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/admin/Sidebar";
import store from "@/store";
export default {
  components: {
    Sidebar
  },
  data: () => ({
    checkable: false,
    checkedRows: [],
    files: []
  }),
  computed: {
    data() {
      return this.$store.state.files.data;
    },
    search: {
      get() {
        return store.state.files.search;
      },
      set(newValue) {
        store.commit("setFilesSearch", newValue);
      }
    },
    isLoading() {
      return store.getters.isLoading && !store.getters.isRouting ? true : false;
    }
  },
  watch: {
    search: _.debounce(function() {
      if (!this.search) this.getFiles();
    }, store.getters.delay)
  },
  methods: {
    userHasPermission(data) {
      return store.getters.userHasPermission(data);
    },
    getFiles() {
      store.dispatch(this.$route.meta.dispatch);
    },
    onUpload() {
      store.dispatch("saveFilesForm", this.files).finally(() => {
        this.files = [];
      });
    },
    onDelete() {
      store
        .dispatch("deleteSelection", {
          message: `Do you really want to delete these files?`,
          action: "deleteSelection",
          ids: this.checkedRows,
          path: "files",
          push: "Files",
          dispatch: this.$route.meta.dispatch
        })
        .then(() => {
          this.checkedRows = [];
          this.checkable = false;
        });
    },
    onSelect() {
      this.getFiles();
    },
    onBulkSelect(id) {
      if (this.checkable) {
        let i = _.indexOf(this.checkedRows, id);
        if (i > -1) {
          this.checkedRows.splice(i, 1);
        } else {
          this.checkedRows.push(id);
        }
      }
    },
    onScroll() {
      let currentScroll =
        this.$refs["files-container"].scrollHeight -
        this.$refs["files-container"].scrollTop;
      let endScroll = this.$refs["files-container"].offsetHeight;
      if (_.floor(currentScroll) === endScroll) {
        store.commit("incrementFilesTake");
        this.getFiles();
      }
    },
    itemSelected(id) {
      if (this.checkable) {
        let i = _.indexOf(this.checkedRows, id);
        if (i > -1) {
          return true;
        } else {
          return false;
        }
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.meta.dispatch) {
      store.dispatch(to.meta.dispatch).then(() => {
        next();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.files-container {
  width: 100%;
  height: 80vh;
  max-height: calc(80vh);
  overflow-y: scroll;
}
.file-item {
  border: 2px solid rgba(0, 0, 0, 0.1);
}
.file-item-selected {
  border: 2px solid hsl(141, 71%, 48%) !important;
}
</style>
